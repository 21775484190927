import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/Tos',
    name: 'Tos',
    component: () => import('@/views/TosView.vue'),
  },
  {
    path: '/Toa',
    name: 'Toa',
    component: () => import('@/views/ToaView.vue'),
  },
  {
    path: '/Scn',
    name: 'Scn',
    component: () => import('@/views/ScnView.vue'),
  },
  {
    path: '/Ccn',
    name: 'Ccn',
    component: () => import('@/views/CcnView.vue'),
  },
  {
    path: '/Yun',
    name: 'Yun',
    component: () => import('@/views/YunView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/aboutView.vue'),
  },
  {
    path: '/matchine',
    name: 'matchine',
    component: () => import('@/views/matchineView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
