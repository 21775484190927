import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import VueAwesomeSwiper from 'vue-awesome-swiper'  //引入这个npm包
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
import 'animate.css';
import wow from 'wowjs'
import "wowjs/css/libs/animate.css"
import AOS from 'aos'
import 'aos/dist/aos.css'
import "./assets/css/common.css";

Vue.use(AOS)
Vue.prototype.$wow = wow
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')